.form {
  padding: 0 2rem;
}
.contact {
  overflow-y: scroll;
}
.fnavn,
.mail {
  height: 3rem;
  padding: 1rem 0;
}
.contact input,
.besked,
select,
option {
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  outline-style: none;
}

select,
option {
  background-color: #efece7;
}

input.fnavn,
input.mail,
textarea.besked {
  background-color: #dbaba2;
  opacity: 0.9;
  width: 100%;
  padding: 0 1rem;
  font-size: 0.8rem;
  font-family: "Montserrat";
}

.contact textarea {
  padding-top: 1rem;
}

.send {
  font-weight: 400;
  font-size: 1rem;
  padding: 0.2rem 1.5rem;
  background-color: #efece7;
  opacity: 0.9;
  height: 2rem;
  width: 8rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  outline: none;
  box-shadow: 0px 0px 5px rgb(197, 197, 197);
  &:hover {
    transition: 0.5s;
    box-shadow: 0px 0px 10px rgb(197, 197, 197);
  }
}

.error {
  padding-left: 2rem;
  color: rgb(159, 75, 75);
}

.hidden_subject {
  visibility: hidden;
}

.select,
.botInput {
  visibility: hidden;
  height: 1px;
  margin: 0;
  padding: 0;
}
select {
  margin: 1rem 0 0 0;
  height: 35px;
  padding: 0 0.5rem;
}

.contact .skriv_tekst {
  max-width: 65ch;
}

.contact {
  overflow: scroll;
  height: calc(100% - 60px);
  z-index: 1;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  //height: calc(85% - 70px);
  justify-content: center;
  align-items: flex-start;
  //  overflow: scroll;
  height: calc((var(--vh, 1vh) * 100) - 215px);
}

.contact_pic {
  flex-basis: 400px;
  flex-grow: 1;
  height: 100%;
  height: auto;
  max-height: 400px;
  max-width: 400px;
  padding: 0;
  margin-bottom: 1rem;
}

.contact_pic img {
  object-fit: contain;
  width: 100%;
  height: auto;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.contact_wrapper {
  flex-basis: 400px;
  flex-grow: 1;
  margin-bottom: 0;
}

select.classic {
  appearance: none;
  padding-right: 3rem;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, rgb(179, 179, 179), rgb(179, 179, 179));
  background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.social {
  display: flex;
  flex-wrap: nowrap;
  //padding: 0 0 2rem 2rem;

  width: 100%;
  justify-content: flex-end;
  height: calc(5% - 70px);
  align-items: flex-start;
}

.contact .social {
  position: absolute;
  top: 6.3rem;
}

.social img {
  object-fit: contain;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  padding: 0.75rem;
  padding: 0 2rem 0.75rem 0;
}

.contact .social img {
  width: 3rem;
  padding: 0rem 1rem 0.75rem 0rem;
}

p#g-recaptcha-error {
  margin-top: 0;
}
.social a {
  margin: 0;
  padding: 0;
  top: auto;
  left: auto;
}

.classic.noMargin {
  margin: 0.5rem 0 1.5rem 0;
}

@media (min-width: 450px) {
  .contact .social img {
    width: 4rem;
    padding: 0 2rem 0.75rem 0;
  }
}
@media (min-width: 700px) {
  .contact .social {
    top: 5rem;
  }
}
@media (min-width: 900px) {
  .contact_pic,
  .contact_wrapper {
    width: 50%;
  }

  .contact h1 {
    margin-top: 2rem;
  }

  .contact_pic,
  .contact_pic img {
    padding: 0;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
  }

  .contact_pic {
    height: 100%;
  }
}
