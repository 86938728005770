.desk {
  display: none;
}

nav {
  width: 100%;
  height: 60px;
  text-align: center;
  position: fixed;
  background-color: white;
  top: 0.5rem;
  left: 0;
  z-index: 2;
}

.desk {
  z-index: 2;
  border-bottom: 1px solid black;
}

.streg {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 1rem;
  border-bottom: 1px solid black;
  pointer-events: none;
  z-index: 12;
}

nav .burger_wrap {
  z-index: 11;
}

.mobile_menu,
.desk_menu {
  display: inline-block;
  font-size: 1.1rem;
  margin: 0;
  top: 1.3rem;
}

a.linkPerson,
a.linkPortfolio,
a.linkPil {
  margin: 0;
  padding: 0;
  color: inherit;
  position: static;
  width: 100%;
}

.desk_menu {
  font-size: 0.9rem;
}

.kryds2 {
  position: absolute;
  right: 2rem;
}

.kryds1 {
  position: absolute;
  left: 2rem;
}

.desk_person,
.desk_portfolio,
.desk_punkt {
  z-index: 13;
}

.person,
.desk_person {
  position: absolute;
  text-align: right;
  right: 0;
  letter-spacing: 2px;
  padding: 2.3rem 2rem 2.3rem 2rem;
  top: -1rem;
}

.desk_portfolio,
.portfolio {
  left: 0rem;
  padding: 2.3rem 2rem 2.3rem 2rem;
  top: -1rem;
  position: absolute;
  letter-spacing: 2px;
}

.kryds1,
.kryds2 {
  top: 0.2rem;
  transform: scale(0);
}

.home,
.desk_home {
  margin: 0 auto;
  padding-top: 0.6rem;
}

.desk_home,
.home {
  width: 100px;
}

.burger1,
.burger2 {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9;
  background-color: white;
  margin-top: 60px;
}

.burger2 {
  right: -100vw;
}

.burger1 {
  right: 100vw;
  text-align: left;
}

.right {
  text-align: right;
}

ul {
  margin-top: 10vh;
  padding: 0;
}

nav li {
  color: black;
  padding: 1rem 2rem;
  list-style: none;
  font-size: 1.1rem;
  line-height: 5.7rem;
  letter-spacing: 3px;
}

.desk_right li,
.desk_left li {
  display: inline;
  font-size: 0.9rem;
  letter-spacing: 2px;
}

.desk_right,
.desk_left {
  position: fixed;
  height: 60px;
  top: -1rem;
  margin-top: 0;
}

.desk_right {
  right: 0rem;
}

.desk_left {
  left: 0rem;
}

.kryds1 img,
.kryds2 img {
  padding: 0.9rem;
  width: 3.5rem;
  height: 3.5rem;
}

.kryds1 {
  left: 3rem;
}

.kryds2 {
  right: 3rem;
}

.home img,
.desk_home img {
  width: 2.5rem;
  height: 2.5rem;
}

@media (min-width: 501px) {
  .mobile_menu {
    font-size: 0.9rem;
  }
}
@media (min-width: 501px) {
  .mobile_menu {
    font-size: 0.9rem;
  }
}
@media (min-width: 700px) {
  .mobile {
    display: none;
  }

  .desk {
    display: block;
  }

  .home_left {
    transition: 1.5s;
    transform: translateX(23vw);
  }

  .home_right {
    transition: 1.5s;
    transform: translateX(-23vw);
  }

  nav {
    top: 0;
  }
}

@media (min-width: 900px) {
  .desk li {
    padding: 2rem 2rem;
  }

  .home_left {
    transform: translateX(23vw);
  }

  .home_right {
    transform: translateX(-23vw);
  }
}
