/* 
 * 00 IMPORT PARTIALS
 * 01: GENERELT
 * 02: LOADING
 * 03: INDEX
 * 04: OM 
 * 05: PORTFOLIO
 * 06: KONTAKT
 * 07: ANIMATIONER
 * 08: MEDIAQUERIES
 * 09: DIVERSE
*/

/*--------------IMPORT PARTIALS--------------*/
@use 'partials/gallery.scss';
@use 'partials/menu.scss';
@use 'partials/cv.scss';
@use 'partials/sideMenu.scss';
@use 'partials/contact.scss';

/*--------------EXPLORER--------------*/
.explorer {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .explorer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .explorer div {
    margin: 30vh auto;
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }

  .explorer h2 {
    padding: 0;
  }
  .explorer p {
    font-size: 0.8rem;
  }

  .thanks {
    font-size: 1.2rem;
  }
}

/*--------------GENERELT--------------*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: "Montserrat";
  font-weight: 400;
  color: black;
  font-size: 0.8rem;
  //overflow-x: hidden;
  overflow: auto;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

a {
  color: black;
  text-decoration: none;
  position: relative;
  left: 1rem;
  top: 1rem;
}

.home,
.portfolio,
.person,
.kryds1,
.kryds2,
.desk_person,
.desk_punkt,
.desk_home,
.desk_portfolio,
li,
.luk,
.portfolio_klonen,
.pil_ned,
.left_arrow,
.right_arrow,
.cv_link,
.documentation,
.pic {
  cursor: pointer;
}

h1 {
  padding-left: 2rem;
  font-size: 2rem;
  color: #285d67;
}

h2 {
  font-family: serif;
  font-family: "Libre Baskerville";
  font-style: italic;
  padding: 0 2rem;
  color: #dbaba2;
  font-weight: 400;
}
h3 {
  font-family: sans-serif;
  font-family: "Montserrat";
  padding-left: 2rem;
  color: #dbaba2;
  font-weight: 400;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  width: 0rem;
  height: 0;
  visibility: hidden;
}

* {
  scrollbar-width: none;
}

::selection,
::-moz-selection {
  background: #ddc9c7;
}
.beskrivelse::selection,
.beskrivelse::-moz-selection {
  background: #285d67;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  height: 75px;
  width: 100%;
  background-color: white;
  z-index: 2;
}

nav p,
nav li {
  color: #285d67;
  font-weight: 600;
}
footer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
footer p {
  padding-left: 2rem;
  margin-bottom: 2rem;
  width: 50%;
}

footer .social {
  width: 50%;
  justify-content: flex-end;
  padding-right: 0;
  z-index: 0;
}
footer .social img {
  padding-right: 2rem;
}
.year {
  letter-spacing: 3px;
}
/*--------------LOADING--------------*/

#scrollbar {
  position: fixed;
  bottom: 0;
  height: 8px;
  width: 100%;
  transform: translate(calc(-100% + var(--position) * 100%), 0);
  background-color: #f7bfb4;
}

.progress-container {
  width: 100%;
  height: 8px;
  background: #fff;
  box-shadow: inset 0 0 6px rgb(226, 192, 187);
}

.progress-bar {
  height: 8px;
  background: #dbaba2;
  width: 0%;
}

.fixed_scroll {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
}

/*--------------INDEX--------------*/

.scroll_to {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 1;
}

.index {
  opacity: 0;
}

#contact {
  cursor: pointer;
}

.index_position {
  width: 100%;
  //height: 100%;
  z-index: 0;
  display: block;
  padding-top: 4rem;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
}

/* .index_position::-webkit-full-page-media,
_:future,
:root .safari_only {
  height: 100vh;
} */

.navn {
  font-family: serif;
  font-family: "Libre Baskerville";
  color: #4a4a4a;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.5px;
  font-size: 2rem;
  margin-bottom: 0.7rem;
}

.ninja {
  font-family: sans-serif;
  font-family: "Montserrat";
  color: #4a4a4a;
  font-weight: 400;
  letter-spacing: 3px;
  max-width: 27ch;
  line-height: 2rem;
  font-style: normal;
}

.intro_heading {
  flex-basis: 50px;
  flex-grow: 1;
  padding-top: 2rem;
  //height: calc(55vh - 75px);
  z-index: 1;
  pointer-events: none;
  position: relative;
}

.logo {
  flex-basis: 200px;
  flex-grow: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splash_pic {
  width: 100%;
  /*   height: 60%; */
  object-fit: contain;
  position: absolute;
  bottom: 0;
  mix-blend-mode: darken;
}

.line {
  height: 2px;
  width: 18vw;
  border-top: 2px solid #285d67;
  margin-left: 2rem;
}

.pil_wrapper {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 1rem;
}
.pil_ned {
  width: 4rem;
  margin-top: 2rem;
}
.green_bgcolor {
  background-color: #daeade;
}
/*--------------OM--------------*/

.om {
  width: 100%;
  height: auto;
  background-color: white;
  /*   margin-top: 100vh;
  margin-top: calc(var(--vh, 1vh) * 100); */
  padding: 60px 0 2rem;
}

#om {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 60px);
  min-height: calc((var(--vh, 1vh) * 100) - 60px);
}

.aspiring {
  width: 100%;
  padding: 0 2rem;
  line-height: 1.6rem;
  margin-top: 0;
}
.picture_om,
.profile_text {
  flex-basis: 400px;
  flex-grow: 2;
}

.picture_om {
  max-height: 1050px;
}
.profile_pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
figure {
  width: 100%;
  margin: 0;
  padding: 1rem 0 0;
}

.profile_text p {
  line-height: 1.2rem;
}

.profile_text {
  max-width: 70ch;
}

.cv_link {
  font-family: serif;
  font-family: "Libre Baskerville";
  font-weight: 400;
  font-style: italic;
  color: #dbaba2;
  font-size: 1rem;
}

/*--------------PORTFOLIO--------------*/

.portfolioet {
  width: 100%;
  // min-height: 93vh;
  background-color: white;
  padding-top: 80px;
  height: calc((var(--vh, 1vh) * 100) - 75px - 8px);
  > h1 {
    margin-top: 0;
  }
}

.portfolio_liste {
  max-width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 15rem;
  margin: 0 auto;
}

.portfolio_skabelon,
.portfolio_klonen,
.pic,
.projektbillede_wrapper {
  width: 100%;
}

.portfolio_klonen {
  align-items: flex-start;
}

.projekt_billede_liste {
  width: 100%;
  height: auto;
  padding: 0 1rem 0 5rem;
  justify-content: center;
  display: flex;
}

.titel {
  // padding: 1.2rem 0rem 3rem 0rem;
  padding: 1.8rem 0rem 2rem 0rem;
  margin: 0;
  font-size: 1.5rem;
}

.embed {
  width: 100%;
  height: 57vw;
  //height: auto;
  max-width: 1200px;
  max-height: 977px;
  // min-width: 740px;
  //min-height: 603px;
  border: none;
  margin-bottom: 2rem;
}
.youtube {
  height: 72vw;
  max-height: 675px;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.projektbillede_wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  //max-width: 950px;
}

.projektbillede_wrapper img {
  object-fit: contain;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.projektbillede_wrapper h2 {
  padding-left: 0;
  color: #285d67;
}
.projektbillede_wrapper video,
.youtube {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  border: 1px solid #e4ccc8;
}

.portfolio_klonen {
  position: relative;
}

.portfolioet .titel_klonen {
  font-size: 0.8rem;
  margin: 0;
  padding: 1rem;
  color: black;
  height: 3rem;
  position: absolute;
  bottom: 0rem;
  font-weight: 600;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.hojde {
  height: 3rem;
  opacity: 0;
  background: linear-gradient(0deg, rgba(228, 204, 200, 1) 20%, rgba(205, 205, 205, 0) 20%);
  background: rgba(228, 204, 200, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
}

.portfolio_klonen:hover .hojde {
  transition: 0.7s;
  opacity: 1;
}

.projekt_popup {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
}

.hide {
  display: none;
}

.beskrivelse_wrapper {
  height: 100%;

  width: 4rem;
  background: rgba(228, 204, 200, 1);
  position: fixed;
  overflow-y: scroll;
  left: 0;
  top: 0;
  z-index: 1;
  div {
    height: auto;
    padding-bottom: 4rem;
  }
}

.projekt_popup::-webkit-scrollbar,
.beskrivelse_wrapper::-webkit-scrollbar {
  display: none;
}

.info,
.tilbage,
.luk img {
  position: absolute;
  top: -1rem;
  right: -1.5rem;
  padding: 2rem;
  width: 7rem;
  height: 7rem;
}

.luk img {
  left: auto;
  right: 0rem;
  position: fixed;
}

.beskrivelse_wrapper {
  cursor: url("/static/billeder/dotthei1.png"), auto;
  overflow-x: hidden;
}

.tilbage {
  left: auto;
  right: -1rem;
  cursor: url("/static/billeder/tilbage_pil_lille.png"), auto;
}
.kategori {
  white-space: pre-wrap;
  letter-spacing: 3px;
}
.beskrivelse {
  color: black;
  margin-top: 0rem;
  padding: 0rem 2rem 2rem;
  overflow-y: scroll;
  opacity: 0;
  white-space: pre-wrap;
  max-width: 65ch;
  line-height: 1.3rem;
}

.beskrivelse_wrapper::-webkit-scrollbar,
.beskrivelse::-webkit-scrollbar {
  width: 0;
  display: none;
}

.link,
.documentation,
.link2 {
  // padding: 1rem 0;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 1rem;
  opacity: 0;
  display: block;
  text-decoration: overline;
}
.grecaptcha-badge {
  visibility: hidden;
}
/* .padding {
  padding: 0 0 2rem 1rem;
} */
.documentation {
  height: 2rem;
  //padding: 1rem 0 3rem 1rem;
}

.a {
  cursor: url("/static/billeder/Globe_pil.png"), auto;
}

.single {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 20;
  //height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  //height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  padding: 4rem 1rem;
}

.single {
  text-align: center;
}

.single .beskriv {
  max-width: 65ch;
  text-align: left;
  padding-bottom: 2rem;
  margin: 0;
  line-height: 1.2rem;
}
.single .beskriv_wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.879);
}

.et_billede {
  width: 100%;
  /*  max-width: 1200px; */
  max-height: 100%;
  object-fit: contain;
}

.web_video {
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
    max-width: 1200px;
  }
}
.tell,
.tell2 {
  max-width: 65ch;
  margin: 0;
}

.portfolioet .beskriv {
  padding-left: 2rem;
}

.semester {
  opacity: 0;
  margin: 0.5rem 0 2rem;
  color: #285d67;
  font-size: 1.1rem;
}

.overskrift_beskriv {
  opacity: 0;
  padding: 2.5rem 5rem 0rem 2rem;
  margin: 0rem 0 0rem;
}

/*---------------KONTAKT---------------*/

.contact {
  width: 100%;
  height: 75%;
  background-color: white;
}

.skriv_tekst {
  padding: 0 2rem;
  max-width: 100%;
}

.portfolioet p,
.om p {
  padding: 0 2rem;
}

.contact,
.cv {
  width: 100%;
  height: 100%;
  position: fixed;
  padding-top: 80px;
  top: 0;
  left: 0;
  background-color: white;
}

.cv {
  top: 60px;
  padding-top: 0;
}

.contact {
  height: 100%;
}

/*--------------ANIMATIONER--------------*/
/*til burgermenu*/
.height100 {
  transition: 0.5s;
  height: 100%;
}

.height60px {
  transition: 0.5s;
  height: 60px;
}

.height_up_down {
  animation-name: height_up_down_kf;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes height_up_down_kf {
  0% {
    height: 60px;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 60px;
  }
}

.scale_down {
  transition: 1s;
  transform: scale(0);
}

.scale_up {
  transition: 1s;
  transform: scale(1);
}

.transition_right {
  transition: 1s;
  transform: translateX(-100vw);
}

.transition_right_back {
  transition: 1s;
  transform: translateX(0vw);
}

.transition_left {
  transition: 1s;
  transform: translateX(100vw);
}

.transition_left_back {
  transition: 1s;
  transform: translateX(0vw);
}

.smooth_in {
  transition: 2s;
  opacity: 1;
}

.home_left {
  transition: 1.5s;
  transform: translateX(23vw);
}

.home_right {
  transition: 1.5s;
  transform: translateX(-23vw);
}

.home_center {
  transition: 1.5s;
  transform: translateX(0);
}

.fade_out {
  transition: 0.5s;
  opacity: 0;
  z-index: -1;
}

.fade_in {
  transition: 0.5s;
  opacity: 1;
  z-index: 15;
}

.fade_in_slow {
  transition: 2s;
  opacity: 1;
  z-index: 15;
}
.fade_out_slow {
  transition: 2s;
  opacity: 0;
  z-index: 15;
}

.fade_in_back {
  transition: 1s;
  opacity: 1;
}
.fade_out_back {
  transition: 1s;
  opacity: 0;
}

.fade_in_quick {
  transition: 0.5s;
  opacity: 1;
  z-index: 15;
}

.link.fade_in_quick,
.link2.fade_in_quick {
  padding: 0 0 2rem 1rem;
}

.fade_out_quick {
  transition: 0.5s;
  opacity: 0;
  z-index: -1;
}
.fade_in_quick2 {
  transition: 0.5s;
  opacity: 1;
}

.fade_out_quick2 {
  transition: 0.3s;
  opacity: 0;
}

.scale_down_back {
  transition: 1s;
  transform: scale(0);
  z-index: -20;
}

.scale_up_front {
  transition: 1s;
  transform: scale(1);
  z-index: 20;
}

.enlarge {
  transition: 1.5s;
  width: 100%;
}

.smaller {
  transition: 1.5s;
  width: 4rem;
}

.greyed_out {
  filter: invert(0) brightness(3.5) grayscale(1);
}

.color_green {
  filter: hue-rotate(120deg) brightness(110%) contrast(90%) saturate(30%);
}
.color_greeny {
  filter: hue-rotate(100deg) brightness(120%) contrast(120%) saturate(30%);
}

.color_green3 {
  filter: contrast(110%) saturate(70%) brightness(130%);
}
.contact_color {
  filter: hue-rotate(0deg) grayscale(0) contrast(100%) saturate(50%) brightness(105%);
}
.contact_colorista {
  filter: saturate(100%) grayscale(0.8) brightness(80%) contrast(170%) hue-rotate(290deg) invert(1);
}

//INDEX

.bgcolor {
  animation: bgcolor 0.3s cubic-bezier(0.76, 0.64, 0.49, -0.15) 0s 1 forwards;
}

@keyframes bgcolor {
  0% {
    background-color: #daeade;
  }
  50% {
    background-color: #daeade;
  }
  100% {
    background-color: #ffd5cd;
  }
}

.textcolor {
  transition: 2s;
  color: #285d67;
  animation: textcolor 0.5s cubic-bezier(0.76, 0.64, 0.49, -0.15) 0s 1 forwards;
}

@keyframes textcolor {
  0% {
    color: #4a4a4a;
  }
  50% {
    color: #4a4a4a;
  }
  100% {
    color: #285d67;
  }
}
.pulse {
  -webkit-animation: pulse 1s ease-in-out infinite forwards;
  animation: pulse 1s ease-in-out infinite forwards;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

picture.splash {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

/*--------------MEDIAQUERIES--------------*/

@media (max-width: 450px) and (max-height: 600px) {
  .splash_pic {
    width: 100%;
    height: 55%;
    object-fit: contain;
    right: 0;
  }

  .intro_heading {
    height: auto;
    position: relative;
  }
}

@media (min-width: 500px) {
  .titel {
    font-size: 2rem;
  }

  //INDEX
  .index_position {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .intro_heading {
    flex-basis: 200px;
    flex-grow: 1;
    margin-top: 0rem;
    height: 30vh;
  }

  .splash_pic {
    object-fit: cover;
    width: 80%;
    height: 100%;
    right: 0;
  }
}

@media (max-width: 600px) and (max-height: 800px) {
  .splash_pic {
    object-position: 0% 100%;
  }
}
@media (max-height: 650px) {
  .splash_pic {
    object-position: 0% 10%;
  }
  .om {
    margin-top: 0;
  }
}
@media (min-width: 600px) {
  .intro_heading {
    height: 35vh;
  }
}

@media (min-width: 650px) {
  .portfolio_liste {
    grid-template-columns: repeat(3, 1fr);
  }

  .color {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(65%) contrast(100%) sepia(0.9) hue-rotate(320deg) saturate(40%) brightness(125%) contrast(120%);
  }

  .hojde:hover + .color {
    filter: none;
  }

  .projektbillede_wrapper {
    padding: 0;
  }

  .enlarge {
    transition: 1.5s;
    width: 75vw;
  }

  .pil_ned {
    width: 6rem;
    margin-top: 1rem;
  }
}

@media (min-width: 700px) {
  .portfolioet,
  .person,
  .contact {
    padding-top: 60px;
    // height: calc((var(--vh, 1vh) * 100) - 60px - 30px);
  }
  .portfolioet {
    height: calc((var(--vh, 1vh) * 100) - 60px);
    > h1 {
      margin-top: 2rem;
    }
  }

  header {
    height: 60px;
  }

  .intro_heading {
    flex-basis: 200px;
    flex-grow: 1;
    height: auto;
  }

  .logo {
    flex-basis: 200px;
    flex-grow: 1;
    width: 100%;
    height: 73vh;
    object-fit: cover;
  }
}

@media (max-width: 650px) {
  .embed,
  .iframe_txt {
    display: none;
  }
}

@media (min-width: 750px) and (max-height: 1050px) {
  .intro_heading {
    flex-basis: 500px;
    flex-grow: 0;
    height: auto;
  }
}

@media (min-width: 550px) and (max-height: 450px) {
  .logo {
    width: 50%;
    height: 100%;
  }

  .intro_heading {
    flex-basis: 200px;
    width: 50%;
    height: 100%;
  }

  .ninja {
    font-size: 1rem;
    padding: 0 2rem;
  }
  .navn {
    font-size: 1.5rem;
    padding: 0 2rem;
  }
}
@media (min-width: 600px) and (max-height: 450px) {
  .logo {
    width: 50%;
    height: 100%;
  }

  .intro_heading {
    flex-basis: 300px;
    width: 50%;
    height: 100%;
  }
}

@media (min-width: 800px) {
  .portfolio_liste {
    grid-template-columns: repeat(4, 1fr);
  }

  .enlarge {
    transition: 1.5s;
    width: 50vw;
  }

  //INDEX
  .index_position {
    align-items: flex-end;
  }

  .intro_heading {
    height: 100%;
    margin: 0;
    padding-top: 0;
  }

  .logo {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .line {
    width: 10vw;
  }

  figure {
    padding: 0;
  }

  .singleBillede {
    margin: 0;
    padding-bottom: 2rem;
    height: auto;
  }
}

//IPAD PRO
@media (max-device-width: 1370px) and (orientation: landscape) {
  .logo {
    width: 60%;
  }
}
@media (max-device-width: 1050px) and (orientation: portrait) {
  .logo {
    width: 100%;
  }
}

@media (min-width: 800px) and (max-height: 450px) {
  .logo {
    height: 50%;
    width: 50%;
  }
  .ninja {
    font-size: 1.5rem;
  }
  .navn {
    font-size: 2rem;
  }
}

@media (min-width: 900px) {
  .enlarge {
    transition: 1.5s;
    width: 40vw;
  }
  .intro_heading {
    width: 50%;

    margin-left: 2rem;
  }
  .navn {
    font-size: 2.5rem;
    padding: 0 2rem;
  }

  .ninja {
    font-size: 1.5rem;
    line-height: 2.3rem;
    padding: 0 2rem;
  }
}
@media (min-width: 900px) and (max-height: 800px) {
  .intro_heading {
    width: 50%;
  }
  .logo {
    width: 50%;
  }
  .projekt_billede_liste {
    padding: 0 5rem 0 10rem;
  }
}

@media (min-width: 1000px) {
  .enlarge {
    transition: 1.5s;
    width: 35vw;
  }
}

@media (min-width: 900px) and (max-height: 700px) {
  .splash_pic {
    height: 100%;
    width: 50%;
  }
}

@media (min-width: 1100px) and (max-height: 1095px) {
  .intro_heading {
    width: 42%;
    margin-left: 2rem;
  }
  .navn {
    font-size: 3rem;
  }

  .ninja {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (min-width: 850px) {
  .splash_pic {
    height: 80%;
    width: 50%;
  }

  .ninja {
    line-height: 3rem;
  }
  .projekt_billede_liste {
    padding: 0 3rem 0 7rem;
  }
}
@media (min-width: 1200px) {
  .projektbillede_wrapper {
    max-width: none;
  }
}
@media (min-width: 1300px) {
  .intro_heading {
    margin-top: 2rem;
  }

  .navn {
    font-size: 3rem;
  }

  .ninja {
    font-size: 2rem;
  }
}

@media (min-width: 1300px) {
  .logo {
    max-height: 100vh;
  }
}

@media (min-width: 1400px) {
  .splash_pic {
    height: 80%;
    width: 40%;
  }
}
@media (min-width: 1500px) {
  .line {
    width: 6vw;
  }
  .projekt_billede_liste {
    padding: 0 15rem;
  }
}

@media (max-height: 400px) {
}
@media (min-width: 1000px) and (max-height: 800px) {
  .splash_pic {
    height: 80%;
    width: 50%;
  }
}
@media (min-width: 1100px) and (max-height: 800px) {
  .splash_pic {
    height: 100%;
    width: 40%;
  }
}
@media (min-width: 1700px) {
  .splash_pic {
    height: 80%;
    width: 30%;
  }
  .picture_om {
    max-height: 800px;
  }
}
/*-----------------DIVERSE-------------------*/
.hide {
  display: none;
}
